import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { FaFacebookSquare, FaLinkedin } from 'react-icons/fa';
import styled from 'styled-components';
import { SEO } from '../components';
import GeoMap from '../components/GeoMap/GeoMap';
import Layout from '../components/Layout/Layout';
import { FACEBOOK_URL, LINKEDIN_URL } from '../configuration';
import { rem, theme } from '../theme';

const AddressRow = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: ${theme.colors.font.secondary};
  padding-bottom: 32px;

  @media only screen and (min-width: ${theme.breakpoints[3]}px) {
    justify-content: left;
    padding-left: 148px;
    flex-direction: row;
    padding-top: 48px;
    padding-bottom: 0;
  }
`;

const AddressPart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  font-size: ${rem(3)};

  & h3 {
    color: ${theme.colors.brandColor[600]};
    text-transform: uppercase;
    margin-top: 0;
  }

  & > a {
    color: ${theme.colors.font.secondary};
    text-decoration: none !important;
    font-size: ${rem(3)};
    display: contents;
  }

  @media only screen and (min-width: ${theme.breakpoints[3]}px) {
    margin-top: 0;
    margin-right: 60px;
    justify-content: left;
    align-items: flex-start;
  }
`;

const SocialMedia = styled.div`
  display: flex;
  justify-content: center;
  color: black;
  cursor: pointer;

  & > a {
    color: ${theme.colors.black};
    font-size: ${rem(5)};
    & > svg {
      margin-left: 8px;
      margin-right: 8px;
      &:hover {
        color: ${theme.colors.brandColor[600]};
      }
    }
  }

  @media only screen and (min-width: ${theme.breakpoints[3]}px) {
    display: none;
  }
`;

const ContactPage = () => {
  const data = useStaticQuery(graphql`
    {
      dataJson(type: { eq: "contact" }) {
        id
        company
        phones
        emails
        address {
          street
          zip
          city
          voivodship
          country
        }
        krs
        nip
        regon
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Contact" />
      <div>
        <GeoMap
          position={[53.13418, 23.14854]}
          zoom={18}
          markerText={data.dataJson.company}
        />
      </div>
      <AddressRow>
        <AddressPart>
          <h3>Phone & email</h3>
          {data.dataJson.phones.map((el: string) => {
            return <div key={el}>{el}</div>;
          })}
          {data.dataJson.emails.map((el: string) => {
            const mailHref = 'mailto:' + el;
            return (
              <a key={el} href={mailHref}>
                office[at]b4limits.pl
              </a>
            );
          })}
        </AddressPart>
        <AddressPart>
          <h3>Address</h3>
          <div>{data.dataJson.address.street}</div>
          <div>
            {data.dataJson.address.zip} {data.dataJson.address.city}
          </div>
          <div>
            {data.dataJson.address.voivodship}, {data.dataJson.address.country}
          </div>
        </AddressPart>
        <AddressPart>
          <h3>Legal info</h3>
          <div>KRS: {data.dataJson.krs}</div>
          <div>NIP: {data.dataJson.nip}</div>
          <div>REGON: {data.dataJson.regon}</div>
        </AddressPart>
        <AddressPart>
          <SocialMedia>
            <a href={LINKEDIN_URL} target="_blank">
              <FaLinkedin></FaLinkedin>
            </a>
            <a href={FACEBOOK_URL} target="_blank">
              <FaFacebookSquare></FaFacebookSquare>
            </a>
          </SocialMedia>
        </AddressPart>
      </AddressRow>
    </Layout>
  );
};

export default ContactPage;
